export default {
  proxy_path: "https://ww4pnnzfg4.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://ir438u7jd1.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyA5Ofaf2rUJ4Qp9Xo1YVA2rDP4-f0PQ3Es",
  main_company_term_id: "6",
  crm_base_url: "https://crm.emeco.forwoodsafety.com",
  forwood_id_url: "https://id.emeco.forwoodsafety.com?redirect_uri=https://mapreport.emeco.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.emeco.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_XUVnRJ0bp",
    userPoolWebClientId: "2283353c21gtp3bjpuukrhfj01",
    cookieStorage: {
      domain: ".emeco.forwoodsafety.com",
      secure: true
    }
  }
};
